export default {
  login: {
    description: 'Log in to your existing Hubble account',
    title: 'Log in',
  },

  signup: {
    description: 'Sign up for a new Hubble account',
    title: 'Sign up',
  },
};
