import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import BuildingsApi from 'site-react/api/BuildingsApi';
import { HubbleLogo, Section } from 'site-react/components/page';
import { Centered } from 'site-react/components/utility';
import config from 'site-react/config';
import Signup, { BookingOverview } from 'site-react/features/Signup';
import Analytics from 'site-react/helpers/Analytics';
import getAppUrl from 'site-react/helpers/getAppUrl';
import logError from 'site-react/helpers/logError';
import sanitiseQuery from 'site-react/helpers/signup/sanitiseQuery';
import { BuildingPropTypes } from 'site-react/proptypes';

import meta from '../content/meta/login';
import PageLayout from '../layouts/PageLayout';

const LoginPage = ({
  bookingDuration = null,
  building = {},
  error = null,
  isBookingInProgress = false,
  product = null,
  referralSource = null,
  returnUrl = null,
}) => {
  useEffect(() => {
    Analytics.setPageProperties({
      page_category: 'user-profile',
      page_type: 'login',
    });

    Analytics.pageTrack('Login');
  }, []);

  return (
    <PageLayout isFooterHidden isNavigationHidden meta={meta.login}>
      <Section verticalPadding="md">
        <Centered>
          <HubbleLogo size={90} />
        </Centered>
        <Signup
          errors={error ? [error] : []}
          isAbleToRedirect
          isBookingInProgress={isBookingInProgress}
          isModeSwitcherLink
          referralSource={referralSource}
          returnUrl={returnUrl}
        >
          {isBookingInProgress && (
            <BookingOverview
              bookingDuration={bookingDuration}
              product={product}
              selectedBuilding={building}
            />
          )}
        </Signup>
      </Section>
    </PageLayout>
  );
};

export async function getServerSideProps({ query, res }) {
  let nextParam;
  try {
    nextParam = decodeURI(query?.next);
  } catch (error) {
    if (error instanceof URIError) {
      // If the error is from decodeURI it's likely that the URL is malformed.
      // Letting this bubble up will cause a 500 error and log an error which isn't helpful.
      // Instead, we'll return a 404 so that the error is handled gracefully without logging.
      if (res) {
        res.statusCode = 404;
      }
      return { notFound: true };
    } else {
      throw error;
    }
  }
  let bookingDuration;
  if (nextParam) {
    const nextParamAsUrl = new URL(nextParam, getAppUrl(config));
    const nextParamParams = new URLSearchParams(nextParamAsUrl.searchParams);
    bookingDuration = nextParamParams.get('bookingDuration');
  }

  const { error, returnUrl, referralSource } = sanitiseQuery(query);
  const buildingsApi = new BuildingsApi();

  const isBookingInProgress = referralSource === 'hubblehq-booking';

  const decodedURL = decodeURIComponent(returnUrl);

  const buildingId =
    isBookingInProgress &&
    encodeURIComponent(
      decodedURL.split('/pass/office-space/')[1].split('/')[0],
    );

  const urlContainsOption = decodedURL.split('option=').length > 1;

  const product =
    isBookingInProgress &&
    encodeURIComponent(
      urlContainsOption
        ? decodedURL.split('option=')[1].split('&')[0]
        : 'coworking',
    );

  try {
    let building;
    if (isBookingInProgress) {
      const buildingResponse = await buildingsApi.getBuildingById(buildingId);
      building = buildingResponse.body;
    }

    return {
      props: {
        error,
        isBookingInProgress,
        product,
        referralSource,
        returnUrl: decodedURL,
        ...(building && { building }),
        ...(bookingDuration && { bookingDuration }),
      },
    };
  } catch (error) {
    const { body, statusCode } = error;
    if (statusCode !== 404 && statusCode !== 410) {
      logError(error);
    }

    return {
      props: {
        error: {
          message: body?.message || 'Something went wrong',
          statusCode: statusCode,
        },
        isBookingInProgress,
        product,
        referralSource,
        returnUrl: decodedURL,
      },
    };
  }
}

LoginPage.propTypes = {
  /**
   * Building data, returned by BuildingsApi.
   */
  building: BuildingPropTypes,

  /**
   * What custom content, if any, should be shown alongside the signup form?
   */
  content: PropTypes.oneOf([
    'host',
    'hybrid',
    'office-first',
    'pass',
    'remote-first',
    'shortlist',
  ]),

  /**
   * Any exception message that needs to be displayed as a result of an action from the
   * step before signup.
   */
  error: PropTypes.string,

  /**
   * Is the user signing up as part of booking a workspace
   */
  isBookingInProgress: PropTypes.bool,

  /**
   * If isBookingInProgress, what type of workspace if the user booking
   */
  product: PropTypes.string,

  /**
   * Where has this user signed up from?
   */
  referralSource: PropTypes.string,

  /**
   * On successfully logging in, where should we direct this user?
   */
  returnUrl: PropTypes.string,
};

export default LoginPage;
